const incidentAllowedFields = {
  details: ['labels', 'department_id', 'owner_person_id', 'follow_up_contact', 'short_description'],
  response: ['response_date', 'response_date_zone', 'responder_person_id', 'responder_assessment'],
  summary: ['executive_summary'],
  corrective_actions: [
    'actions_taken',
    'corrective_actions_due_date',
    'corrective_actions_required',
    'corrective_actions_completion_date',
    'corrective_actions_recorded_by_person_id',
  ],
  preventative_actions: [
    'recurrence_prevention_required',
    'recurrence_prevention_due_date',
    'recurrence_prevention_comment',
    'recurrence_prevention_completion_date',
    'recurrence_prevention_recorded_by_person_id',
  ],
  internal_information: [
    'other_information',
    'impact_type_id',
    'impact_length',
    'incident_risk_rating_id',
    'cost_implication',
    'report_to_regulatory_agency',
  ],
};

const accidentAllowedFields = {
  summary: [
    'internal_party_involved_id',
    'external_party_involved',
    'person_type_id',
    'pi_director_id',
    'supervisor_id',
    'supervisor_text',
    'accident_setting_id',
  ],
  details: [
    'injury_type_ids',
    'description',
    'accident_class_ids',
    'affected_body_part_ids',
    'body_side_affected',
    'osha_risk_factor_id',
    'osha_case_classification',
    'days_away_from_work',
    'days_on_job_transfer_or_restriction',
    'details_attachments',
    'details_links',
  ],
  treatment: [
    'treatment_physician',
    'treatment_outside_worksite',
    'treatment_facility',
    'treatment_street',
    'treatment_city',
    'treatment_state',
    'treatment_zip',
    'employee_treated_in_emergency_room',
    'employee_hospitalized_overnight',
  ],
  causal_factors: [
    'accident_unsafe_act_ids',
    'accident_unsafe_personal_factor_ids',
    'accident_unsafe_condition_ids',
    'factors_comment',
    'label_ids',
  ],
  corrective_actions: [
    'investigation_recommendations',
    'corrective_actions_due_date',
    'investigation_start_date',
    'investigation_close_out_date',
    'actions_taken',
    'corrective_actions_completion_date',
    'corrective_actions_recorded_by_person_id',
    'investigation_text',
    'investigation_attachments',
    'investigation_links',
  ],
  preventative_actions: [
    'preventive_actions',
    'preventive_actions_due_date',
    'preventive_actions_comments',
    'preventive_actions_completion_date',
    'preventive_actions_recorded_by_id',
  ],
  internal_information: ['internal_notes', 'internal_attachments', 'internal_links'],
};

const shouldSectionBeVisible = (allowedFields, sectionName, fieldsConfig) => {
  if (!fieldsConfig) return false;

  const fields = allowedFields[sectionName];
  const sectionFieldsConfig = fieldsConfig[sectionName];
  if (!fields || !sectionFieldsConfig) return false;

  return fields.some(field => sectionFieldsConfig[field]);
};

export const shouldIncidentSectionBeVisible = (sectionName, incidentFieldsConfig) => {
  return shouldSectionBeVisible(incidentAllowedFields, sectionName, incidentFieldsConfig);
};

export const shouldAccidentSectionBeVisible = (sectionName, accidentFieldsConfig) => {
  return shouldSectionBeVisible(accidentAllowedFields, sectionName, accidentFieldsConfig);
};

export const produceShouldFieldBeVisible = (sectionName, fieldsConfig) => field => {
  if (!fieldsConfig || !sectionName || !field) return false;

  const sectionFieldsConfig = fieldsConfig[sectionName];
  if (!sectionFieldsConfig) return false;

  return sectionFieldsConfig[field];
};
