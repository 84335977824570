import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { produceShouldFieldBeVisible } from '../helpers';
import { FormSection } from '../../../../../components/form/FormSection';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { RichTextField } from '../../../../../components/fields/RichTextField';
import { FilesField } from '../../../../../components/fields/FilesField';
import { LinksField } from '../../../../../components/fields/LinksField';
import { DateField } from '../../../../../components/fields/DateField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentCorrectiveActions = props => {
  const {
    defaultChildrenAttributes,
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('corrective_actions', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_corrective_actions')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('investigation_recommendations')}
        name="accident_investigation_recommendations"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('corrective_actions_due_date')}
        name="accident_corrective_actions_due_date"
        component={DateField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('investigation_start_date')}
        name="accident_investigation_start_date"
        component={DateField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('investigation_close_out_date')}
        name="accident_investigation_close_out_date"
        component={DateField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('actions_taken')}
        name="accident_actions_taken"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('corrective_actions_completion_date')}
        name="accident_corrective_actions_completion_date"
        component={DateField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('corrective_actions_recorded_by_person_id')}
        name="accident_corrective_actions_recorded_by_person_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('investigation_text')}
        name="accident_investigation_text"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('investigation_attachments')}
        name="accident_investigation_attachments"
        component={FilesField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('investigation_links')}
        name="accident_investigation_links"
        component={LinksField}
      />
    </FormSection>
  );
};

export default AccidentCorrectiveActions;
