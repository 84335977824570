import React from 'react';
import styled from 'styled-components';
import InputLabelHint from '../InputLabelHint';
import { colors } from '../../../../assets/styles/colors';
import { fontSize } from '../../../../assets/styles/typography';

const Label = styled.label.attrs({ htmlFor: props => props.inputId })`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  line-height: 36px;
  margin-bottom: 0;
  padding-right: 15px;
`;

const Required = styled.span`
  margin-left: 4px;
  color: ${colors.required};
`;

const HintWrapper = styled.span`
  margin-left: 4px;
`;

const Error = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.error};
  padding-left: 0.5rem;
`;

const Warning = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.gray};
  padding-left: 0.5rem;
`;

const AdditionalContent = styled.div`
  margin-left: 16px;
  font-size: ${fontSize.textSm};
  white-space: nowrap;
`;

export const GrowingWidthInput = props => {
  const {
    name,
    label,
    hint,
    required,
    error,
    warning,
    children,
    additionalContent,
    hidden,
    invisible,
    inputId,
    bottomMargin,
  } = props;
  return (
    <div
      className="d-inline-block w-100"
      hidden={hidden}
      style={{ visibility: invisible ? 'hidden' : 'visible' }}
    >
      <div className="d-flex flex-wrap w-100">
        {(label || hint || required) && (
          <Label name={name} className="d-flex" inputId={inputId}>
            {label}
            {hint && (
              <HintWrapper>
                <InputLabelHint hint={hint} />
              </HintWrapper>
            )}
            {required && <Required>*</Required>}
          </Label>
        )}
        <div className="flex-grow-1">
          <div className="w-100 d-flex align-items-center">
            {children}
            {additionalContent && (
              <AdditionalContent>
                <div className="d-flex align-items-center">{additionalContent}</div>
              </AdditionalContent>
            )}
          </div>
          {error && (
            <div className="w-100 mt-1">
              <Error>{error}</Error>
            </div>
          )}
          {warning && (
            <Warning className="w-100 mt-1">
              <span>
                <i className="fas fa-exclamation-triangle fa-sm mr-1" />
                {warning}
              </span>
            </Warning>
          )}
        </div>
        {bottomMargin ? <div className="w-100 mb-2" /> : <></>}
      </div>
    </div>
  );
};
