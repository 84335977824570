import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { LightButton } from '../../../components/buttons/LightButton';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { Modal } from '../../../components/modals/Modal';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { produceShouldUpdate } from '../../../services/fieldUtils';
import { SelectorField } from '../../../components/fields/SelectorField';

const t = (key, params = {}) => I18n.t(`requests.incident_requests.new_edit.${key}`, params);
const resolutionModalT = (key, params = {}) => t(`modals.resolution.${key}`, params);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    resource_name,
    values: {
      id,
      status,
      reopenable,
      incident_people_group_id,
      incidentAssignedPersonId,
      accidentAssignedPersonId,
      incident_ids,
      submitType,
    },
  } = props;

  const resolutionModalRef = useRef(null);
  const isCompleted = status === 'completed';
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const countOfIncidents = incident_ids.length;

  const subTypeSelectorOptions = [
    'resolveAndCreateIncident',
    'resolveAndAssignIncident',
    'resolveAndCreateAccident',
    'resolveAndAssignAccident',
    'resolve',
  ].map(option => ({
    data: resolutionModalT(`sub_types.${option}`),
    value: option,
  }));

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting, hidden: isCompleted }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={resource_name}
            additionalModalContent={
              countOfIncidents > 0 &&
              I18n.t('activerecord.warning_messages.models.incident_request.related_to_incident', {
                resource_name,
                count: countOfIncidents,
              })
            }
            onSubmit={async () => {
              const { ok, data } = await API.requests.incidentRequests.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.delete', { resource_name }));
                window.location.href = `/requests/incident_requests?status=open`;
              } else {
                showBackendErrorMessage(t('flash.error.delete', { resource_name }), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <LightButton
            hidden={!reopenable}
            onClick={async () => {
              await setFieldValue('submitType', 'reopen');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.reopen')}
          </LightButton>
          <BrandButton
            hidden={!id || isCompleted}
            onClick={() => {
              setFieldValue('submitType', 'resolveAndCreateIncident');
              resolutionModalRef.current.open();
            }}
          >
            {t('footer.resolve')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={resolutionModalT('title', { resource_name })} ref={resolutionModalRef}>
        <ModalSection>
          <FastField
            name="submitType"
            label={resolutionModalT('submit_type')}
            options={subTypeSelectorOptions}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            component={SelectorField}
          />
          <DefaultAttributesGroup
            defaultChildrenAttributes={{
              displayMode: fieldDisplayModes.FULL_WIDTH_FORM_ROW,
              required: true,
              hidden: submitType !== 'resolveAndCreateIncident',
              resourceName: I18n.t('resource_names.incident_request'),
            }}
          >
            <FastField
              name="related_incident_id"
              component={AsyncSelectorField}
              label={I18n.t('activerecord.attributes.incident_request.related_incident_id')}
              hidden={submitType !== 'resolveAndAssignIncident'}
              api={API.selectors.incidents.index}
              selectionApi={API.selectors.incidents.show}
              apiParams={{ without_incident_request_id: id }}
              optionRenderer={htmlOptionRenderer('text')}
              shouldUpdate={produceShouldUpdate([], [], ['submitType'])}
            />
            <FastField
              name="related_accident_id"
              component={AsyncSelectorField}
              label={I18n.t('activerecord.attributes.incident_request.related_accident_id')}
              hidden={submitType !== 'resolveAndAssignAccident'}
              api={API.selectors.accidents.index}
              selectionApi={API.selectors.accidents.show}
              apiParams={{ without_incident_request_id: id }}
              optionRenderer={htmlOptionRenderer('text')}
              shouldUpdate={produceShouldUpdate([], [], ['submitType'])}
            />
            <FastField
              name="incident_people_group_id"
              component={AsyncSelectorField}
              disabled={submitType === 'resolveAndAssignIncident'}
              label={I18n.t('activerecord.attributes.incident.people_group_id')}
              api={API.selectors.peopleGroups.index}
              selectionApi={API.selectors.peopleGroups.show}
              optionRenderer={htmlOptionRenderer('text')}
              onChange={(selection, defaultOnChange) => {
                defaultOnChange(selection);
                setFieldValue('incidentAssignedPersonId', '');
              }}
              shouldUpdate={produceShouldUpdate([], [], ['submitType'])}
            />
            <FastField
              name="incidentAssignedPersonId"
              component={AsyncSelectorField}
              disabled={submitType === 'resolveAndAssignIncident'}
              label={I18n.t('activerecord.attributes.incident_request.assigned_person_id')}
              api={API.selectors.people.index}
              apiParams={
                incident_people_group_id && { with_people_group_id: incident_people_group_id }
              }
              selectionApi={API.selectors.people.show}
              optionRenderer={htmlOptionRenderer('text')}
              headerRenderer={defaultOptionRenderer('full_name')}
              shouldUpdate={produceShouldUpdate([], [], ['submitType', 'incident_people_group_id'])}
            />
            <FastField
              name="accidentAssignedPersonId"
              component={AsyncSelectorField}
              hidden={submitType !== 'resolveAndCreateAccident'}
              disabled={submitType === 'resolveAndAssignAccident'}
              label={I18n.t('activerecord.attributes.incident_request.assigned_person_id')}
              api={API.selectors.people.index}
              selectionApi={API.selectors.people.show}
              optionRenderer={htmlOptionRenderer('text')}
              headerRenderer={defaultOptionRenderer('full_name')}
              shouldUpdate={produceShouldUpdate([], [], ['submitType', 'incident_people_group_id'])}
            />
          </DefaultAttributesGroup>
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <DefaultLink onClick={() => resolutionModalRef.current.hide()}>
                {resolutionModalT('footer.cancel')}
              </DefaultLink>
              <BrandButton
                onClick={async () => {
                  resolutionModalRef.current.hide();
                  if (submitType === 'resolveAndCreateIncident')
                    await setFieldValue('assigned_person_id', incidentAssignedPersonId);
                  if (submitType === 'resolveAndCreateAccident')
                    await setFieldValue('assigned_person_id', accidentAssignedPersonId);
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {resolutionModalT('footer.resolve')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
