import React from 'react';

export const NotSortableContent = props => {
  const { items, renderRow = () => {} } = props;

  return (
    <div className="p-0 m-0">
      {items.map(({ id, data }, index) => (
        <li id={`li-${id}`} key={id} style={{ listStyleType: 'none' }}>
          {renderRow({ id, data, index })}
        </li>
      ))}
    </div>
  );
};
