import React, { useRef } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../components/modals/Modal';
import { API } from '../../../services/api';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { AddRadIsotopeModalContent } from './AddRadIsotopeModalContent';

const t = key => I18n.t(`rad_isotopes_groups.new_edit.${key}`);
const modelT = key => I18n.t(`activerecord.attributes.rad_isotope.${key}`);

export const RadIsotopes = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;

  const addRadIsotopeModalRef = useRef(null);
  const listRef = useRef(null);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.rad_isotopes.title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addRadIsotopeModalRef.current.open,
              );
            }}
          >
            {t('lists.rad_isotopes.add_rad_isotope')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.administration.radIsotopes(id).index}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.rad_isotope')}
            mapData={({ attributes }) => [
              [modelT('name'), attributes.name],
              [modelT('symbol'), attributes.symbol],
              [modelT('half_life_days'), attributes.half_life_days],
              <SimpleListDeletionLink
                key="remove"
                modelName={I18n.t('activerecord.models.rad_isotope')}
                modalWarning={t('lists.rad_isotopes.remove.confirmation_message')}
                modalConfirmationButtonText={t('lists.rad_isotopes.remove.confirmation_button')}
                onSubmit={async () => {
                  const { ok, data } = await API.administration
                    .radIsotopes(id)
                    .removeFromGroup(attributes.id);

                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.rad_isotopes.remove.flash.success'));
                  } else {
                    showBackendErrorMessage(t('lists.rad_isotopes.remove.flash.error'), data);
                  }
                }}
              >
                {t('lists.rad_isotopes.remove.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={t('modals.add_rad_isotope.title')} ref={addRadIsotopeModalRef}>
        {modalProps => <AddRadIsotopeModalContent {...modalProps} {...props} listRef={listRef} />}
      </Modal>
    </>
  );
};
