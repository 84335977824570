import React, { useMemo, useRef, useState } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { Modal } from '../../../components/modals/Modal';
import { MoveToAnotherInventoryModalContent } from '../../../components/other/chemicalSafety/Chemicals/MoveToAnotherInventoryModalContent';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { PrintLabelsButton } from '../../../components/other/chemicalSafety/Chemicals/PrintLabelsButton';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`chemical_safety.chemical_inventories.new_edit.tabs.chemicals.${key}`);
const moveModalT = (key, params) =>
  I18n.t(
    `chemical_safety.chemical_inventories.new_edit.modals.move_to_another_inventory.${key}`,
    params,
  );

export const ChemicalsTable = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    values: { id: chemicalInventoryId, name: chemicalInventoryName },
  } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = useRef(null);
  const moveToAnotherInventoryModalRef = useRef(null);

  const setMoveModalSubtitle = useMemo(
    () =>
      moveModalT('subtitle', {
        chemicals_count: selectedRows.length,
        source_inventory_name: chemicalInventoryName,
      }),
    [chemicalInventoryName, selectedRows.length],
  );

  const additionalLinksDisabled = useMemo(() => selectedRows.length === 0 || isSubmitting, [
    selectedRows,
    isSubmitting,
  ]);

  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          ref={tableRef}
          title={t('title')}
          api={API.chemicalSafety.chemicals().index}
          additionalApiParams={{ chemicals_resource_context: 'chemical_inventory_context' }}
          fixedFilters={{
            with_chemical_inventory_id: chemicalInventoryId,
          }}
          manageColumnsModel="Chemical"
          onCheckboxesChange={rows => setSelectedRows(rows)}
          idToHrefFunction={_id => `/chemical_safety/chemicals/${_id}/edit`}
          forceEmpty={!isDefined(chemicalInventoryId)}
          xlsxReportApi={API.chemicalSafety.chemicals().exportToXlsxReport}
          xlsxReportProps={{ chemical_inventory_id: chemicalInventoryId }}
          xlsxRemoteRequest
          resource="chemical_inventory_chemicals_list"
          showPresets
          additionalLinks={[
            <PrintLabelsButton
              key="print_labels"
              disabled={additionalLinksDisabled}
              ids={selectedRows.map(row => row.key)}
              chemicalInventoryId={chemicalInventoryId}
            />,
            <SmallLightButton
              key="move_to_another_inventory"
              disabled={additionalLinksDisabled}
              onClick={() => {
                moveToAnotherInventoryModalRef.current.open();
              }}
            >
              {t('buttons.move_to_another_inventory')}
            </SmallLightButton>,
            <DeletionLink
              withIcon
              disabled={additionalLinksDisabled}
              modelName={I18n.t('activerecord.models.chemical')}
              hideModalAfterSubmit
              onSubmit={async () => {
                const { ok, data } = await API.chemicalSafety
                  .chemicals(chemicalInventoryId)
                  .multipleDestroy({
                    ids: selectedRows.map(row => row.key),
                  });
                if (ok) {
                  tableRef.current.refresh();
                  showSuccessMessage(t('flash.success.multiple_destroy'));
                } else {
                  showBackendErrorMessage(t('flash.error.multiple_destroy'), data);
                }
              }}
            >
              {t('buttons.multiple_destroy')}
            </DeletionLink>,
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const redirect = newId => () => {
                  window.location.href = `/chemical_safety/chemicals/new?chemical_inventory_id=${newId}`;
                };
                const afterSave = newId => setTimeout(redirect(newId), 1000);
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              }}
            >
              {t('buttons.add_chemical')}
            </DefaultLink>,
          ]}
        />
      </FormSection>
      <Modal title={moveModalT('title')} ref={moveToAnotherInventoryModalRef}>
        {modalProps => {
          const moveModalProps = {
            ...modalProps,
            currentInventoryId: chemicalInventoryId,
            subtitle: setMoveModalSubtitle,
            onSuccess: () => {
              tableRef.current.refresh();
            },
          };
          if (selectedRows.length === 1) {
            moveModalProps.id = selectedRows[0].key;
            moveModalProps.multipleMove = false;
          } else {
            moveModalProps.ids = selectedRows.map(row => row.key);
            moveModalProps.multipleMove = true;
          }

          return <MoveToAnotherInventoryModalContent {...moveModalProps} />;
        }}
      </Modal>
    </>
  );
};
