import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from '../helpers';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { TextField } from '../../../../../components/fields/TextField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentSummary = props => {
  const {
    defaultChildrenAttributes,
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('summary', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_summary')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('internal_party_involved_id')}
        name="accident_internal_party_involved_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('external_party_involved')}
        name="accident_external_party_involved"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('person_type_id')}
        name="accident_person_type_id"
        api={API.selectors.personTypes.index}
        selectionApi={API.selectors.personTypes.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('pi_director_id')}
        name="accident_pi_director_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('supervisor_id')}
        name="accident_supervisor_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('supervisor_text')}
        name="accident_supervisor_text"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('accident_setting_id')}
        name="accident_setting_id"
        api={API.selectors.accidentSettings.index}
        selectionApi={API.selectors.accidentSettings.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
    </FormSection>
  );
};

export default AccidentSummary;
