import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { produceShouldFieldBeVisible } from '../helpers';
import { FormSection } from '../../../../../components/form/FormSection';
import { TextField } from '../../../../../components/fields/TextField';
import { CheckBoxField } from '../../../../../components/fields/CheckBoxField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentTreatment = props => {
  const {
    defaultChildrenAttributes,
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('treatment', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_treatment')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('treatment_physician')}
        name="accident_treatment_physician"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('treatment_outside_worksite')}
        name="accident_treatment_outside_worksite"
        component={CheckBoxField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('treatment_facility')}
        name="accident_treatment_facility"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('treatment_street')}
        name="accident_treatment_street"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('treatment_city')}
        name="accident_treatment_city"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('treatment_state')}
        name="accident_treatment_state"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('treatment_zip')}
        name="accident_treatment_zip"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('employee_treated_in_emergency_room')}
        name="accident_employee_treated_in_emergency_room"
        component={CheckBoxField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('employee_hospitalized_overnight')}
        name="accident_employee_hospitalized_overnight"
        component={CheckBoxField}
      />
    </FormSection>
  );
};

export default AccidentTreatment;
