import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { NumericField } from '../../../components/fields/NumericField';
import { NumericAndUnitFields } from '../../../components/fields/compositeFields/NumericAndUnitFields';
import { RadIsotopes } from './RadIsotopes';

const t = (key, params) => I18n.t(`rad_isotopes_groups.new_edit.${key}`, params);

export const Form = props => {
  const {
    values: { id, maxPositionValue },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.rad_isotopes_group',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.rad_isotopes_group'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="name" required component={TextField} />
          <FastField
            name="position"
            required
            roundPrecision={0}
            component={NumericField}
            warning={t('warnings.position', { max_position_value: maxPositionValue })}
          />
          <FastField
            name="max_activity"
            component={NumericAndUnitFields}
            positiveOnly
            unitName="max_activity_um_id"
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <RadIsotopes {...props} />
    </form>
  );
};
