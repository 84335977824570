import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { validateForm } from '../../services/formHelper';
import reopenValidationSchema from './ReopenValidationSchema';
import validationSchema from './ValidationSchema';
import Form from './Form';
import { API } from '../../services/api';
import { currentDateTime } from '../../services/dateFormatUtils';

const translate = key => I18n.t(`salute_portal.api.findings_controller.${key}`);

const FindingFunctions = {
  apiPaths: {
    create: {
      draft: API.findings.create,
      finalize: API.findings.createFinalized,
    },
    update: {
      draft: API.findings.update,
      finalize: API.findings.updateAndFinalize,
      reopen: API.findings.updateReopen,
    },
    delete: API.findings.delete,
  },
  create: (finding, formikProps, submitType) => {
    return FindingFunctions.apiPaths.create[submitType]({
      inspection_finding: finding,
      send_email_notifications: finding.send_email_notifications,
    }).then(response => {
      const { data, ok } = response;
      if (ok) {
        FindingFunctions.resetVolatileValues(formikProps);
        FindingFunctions.updateValues(data.data.attributes, formikProps);
        window.flashMessage(translate(`flash_messages.success.${snakeCase(submitType)}`));
        if (finding.create_similar) {
          formikProps.props.findingWasCreated(FindingFunctions.prepareNewFindingData(finding));
        } else {
          formikProps.props.findingWasCreated();
        }
      } else {
        FindingFunctions.displayErrorMessage(data, true);
      }
    });
  },
  update: (finding, formikProps, submitType) => {
    return FindingFunctions.apiPaths.update[submitType](finding.id, {
      inspection_finding: finding,
      send_email_notifications: finding.send_email_notifications,
    }).then(response => {
      const { data, ok } = response;
      if (ok) {
        FindingFunctions.resetVolatileValues(formikProps);
        FindingFunctions.updateValues(data.data.attributes, formikProps);
        window.flashMessage(translate(`flash_messages.success.${snakeCase(submitType)}`));
        if (!['disableRpNotifications', 'enableRpNotifications'].includes(submitType)) {
          if (finding.create_similar && submitType !== 'reopen') {
            formikProps.props.findingWasUpdated(FindingFunctions.prepareNewFindingData(finding));
          } else {
            formikProps.props.findingWasUpdated();
          }
        }
      } else {
        FindingFunctions.displayErrorMessage(data);
      }
    });
  },
  delete: (finding, formikProps) => {
    return FindingFunctions.apiPaths
      .delete(finding.id, {
        inspection_finding: finding,
      })
      .then(response => {
        const { ok, data } = response;
        if (ok) {
          window.flashMessage(translate('flash_messages.success.delete'));
          formikProps.props.closeModal();
          formikProps.props.findingProps.refreshList();
        } else {
          FindingFunctions.displayErrorMessage(data, true);
        }
      });
  },
  displayErrorMessage: (data, deleteError = false) => {
    const { message } = data;
    if (message && message.length > 0) {
      window.flashMessage(message[0], true, 'error');
    } else {
      const flashMsg = translate(`flash_messages.${deleteError ? 'delete_error' : 'error'}`);
      window.flashMessage(flashMsg, true, 'error');
    }
  },
  resetVolatileValues: formik => {
    formik.setFieldValue('refresh_investigable_snapshot', false);
  },
  updateValues: (data, formik) => {
    Object.keys(data).forEach(k => {
      formik.setFieldValue(k, data[k]);
    });
  },
  prepareNewFindingData: finding => {
    const newFindingData = {};
    const blackList = [
      'id',
      'general_attachments',
      'general_links',
      'ehs_inspector_id',
      'corrected_date',
      'corrected_comments',
      'corrective_action_attachments',
      'corrective_action_links',
      'submit',
      'inspection_findings_also_notified_people_attributes',
    ];
    Object.keys(FindingFunctions.defaultValues({})).forEach(key => {
      if (!blackList.includes(key)) {
        newFindingData[key] = finding[key];
      }
    });
    return newFindingData;
  },
  prepopulateAlsoNotifiedPeople: (alsoNotifiedPeopleIds = []) =>
    alsoNotifiedPeopleIds.map(id => ({ person_id: id, _destroy: false })),
  defaultValues: (defaults, findingData) => {
    return {
      id: '',
      finding: '',
      regulatory_description: '',
      corrective_action: '',
      website_info: '',
      initiated_at: currentDateTime(),
      follow_up_required: true,
      due_date: '',
      responsible_person_id: '',
      corrected_date: '',
      ehs_inspector_id: '',
      corrected_comments: '',
      location_comment: '',
      wcmc_location: '',
      inspection_finding_definition_id: '',
      violation_id: '',
      investigable_id: '',
      investigable_type: '',
      inspection_checklist_question_id: '',
      inspection_id: '',
      audit_comment: '',
      creator_id: '',
      // eslint-disable-next-line max-len
      inspection_findings_also_notified_people_attributes: FindingFunctions.prepopulateAlsoNotifiedPeople(
        defaults.alsoNotifiedPeopleIds,
      ),
      general_attachments: [],
      general_links: [],
      corrective_action_attachments: [],
      corrective_action_links: [],
      fire_drill_question_answer_id: '',
      status: 'open',
      submit: 'draft',
      internal: defaults.internal,
      people_group_id: '',
      label_ids: [],
      send_email_notifications: findingData && findingData.status === 'finalized',
      refresh_investigable_snapshot: false,
      ...defaults,
    };
  },
};

export const FindingForm = withFormik({
  validate: values => {
    if (values.submit === 'delete') {
      return {};
    }
    if (values.submit === 'reopen') {
      return validateForm(values, reopenValidationSchema);
    }
    return validateForm(values, validationSchema);
  },
  mapPropsToValues: props => {
    return {
      ...FindingFunctions.defaultValues(props.findingProps.defaults, props.finding),
      ...props.finding,
    };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (values.submit === 'cancel') {
      formikProps.props.closeModal();
    } else if (values.submit === 'delete') {
      FindingFunctions.delete(values, formikProps);
    } else if (!values.id) {
      FindingFunctions.create(values, formikProps, values.submit).then(() => {
        formikProps.setSubmitting(false);
      });
    } else {
      FindingFunctions.update(values, formikProps, values.submit).then(() => {
        formikProps.setSubmitting(false);
      });
    }
    formikProps.setTouched({});
  },
})(Form);
