import React from 'react';
import { FastField, withFormik } from 'formik';
import { ModalSection } from '../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';

const t = (key, props = {}) =>
  I18n.t(`rad_isotopes_groups.new_edit.modals.add_rad_isotope.${key}`, props);

const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_isotope'),
          }}
        >
          <FastField
            name="rad_isotope_id"
            label={t('rad_isotope')}
            required
            api={API.selectors.radIsotopes.index}
            apiParams={{ without_group: true }}
            selectionApi={API.selectors.radIsotopes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

export const AddRadIsotopeModalContent = withFormik({
  mapPropsToValues: props => ({
    rad_isotopes_group_id: props.values.id,
    rad_isotope_id: '',
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, listRef } = formikProps.props;
    const api = API.administration.radIsotopes;
    const { ok, data } = await api(values.rad_isotopes_group_id).addToGroup(values.rad_isotope_id);
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
})(Form);
