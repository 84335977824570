import React, { useRef } from 'react';
import { FastField, withFormik } from 'formik';
import styled from 'styled-components';
import {
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';
import { ModalSection } from '../../../modals/ModalSection';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { TextField } from '../../../fields/TextField';
import { AsyncSimpleList } from '../../../lists/AsyncSimpleList';
import { SimpleListDeletionLink } from '../../../lists/SimpleListLinks';
import { API } from '../../../../services/api';
import { DefaultLink } from '../../../links/DefaultLink';
import { fontWeights, fontSize } from '../../../../assets/styles/typography';
import { ModalPaper } from '../../../modals/ModalPaper';

const t = key => I18n.t(`named_filters.new_edit.${key}`);

const SectionTitle = styled.div`
  padding-bottom: 0.5rem;
  padding-top: 0.25rem;
  font-size: ${fontSize.textMd};
  font-weight: ${fontWeights.headings};
`;

const FilterPresetsModalForm = props => {
  const { filters, resource, isSubmitting, setFieldValue, handleSubmit } = props;
  const listRef = useRef(null);

  return (
    <form>
      <ModalSection>
        <SectionTitle>{t('sections.new_named_filter')}</SectionTitle>
        <FastField
          name="name"
          component={TextField}
          disabled={isSubmitting}
          placeholder={t('fields.name_placeholder')}
          displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
          additionalContent={
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                await setFieldValue('filters', filters);
                await setFieldValue('resource', resource);
                await handleSubmit();
              }}
            >
              {t('links.create_named_filter')}
            </DefaultLink>
          }
        />
        <SectionTitle>{t('sections.manage_named_filters')}</SectionTitle>
        <ModalPaper>
          <AsyncSimpleList
            ref={listRef}
            api={() => API.namedFilters.index({ resource })}
            dataKey="data"
            resourceName={t('title')}
            mapData={({ attributes }) => [
              [t('list_fields.name'), attributes.name],
              <SimpleListDeletionLink
                key="remove"
                hidden={false}
                modelName="Preset"
                hideModalAfterSubmit
                modalOnModal
                onSubmit={async () => {
                  const { id } = attributes;
                  const { ok, data } = await API.namedFilters.destroy(id);
                  if (ok) {
                    showSuccessMessage(t('flash.success.destroy'));
                    listRef.current.refresh();
                  } else {
                    showBackendErrorMessage(t('flash.error.destroy'), data);
                  }
                }}
              >
                {t('destroy')}
              </SimpleListDeletionLink>,
            ]}
          />
        </ModalPaper>
      </ModalSection>
    </form>
  );
};

const FilterPresetsFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const filterPreset = FilterPresetsFunctions.prepareValuesToSubmit({ ...values });
    FilterPresetsFunctions.create(filterPreset, formikProps);
  },
  create: async (filterPreset, formikProps) => {
    const { hide, setNamedFilter } = formikProps.props;
    const { ok, data } = await API.namedFilters.create(filterPreset);
    if (ok) {
      hide();
      setNamedFilter(data.data.attributes.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  auxiliaryValues: {},
  backendValuesWhitelist: ['name', 'filters', 'resource'],
});

export const FilterPresetsModalContent = withFormik({
  mapPropsToValues: () => FilterPresetsFunctions.prepareInitialValues({}),
  handleSubmit: FilterPresetsFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(FilterPresetsModalForm);
