import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from '../helpers';
import { RichTextField } from '../../../../../components/fields/RichTextField';
import { DateField } from '../../../../../components/fields/DateField';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const IncidentPreventativeActions = props => {
  const {
    defaultChildrenAttributes,
    values: { incidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('preventative_actions', incidentFieldsConfig)(field),
    [incidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.incident_preventative_actions')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      {shouldFieldBeVisible('recurrence_prevention_required') ? (
        <FastField name="recurrence_prevention_required" component={RichTextField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('recurrence_prevention_due_date') ? (
        <FastField name="recurrence_prevention_due_date" component={DateField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('recurrence_prevention_comment') ? (
        <FastField name="recurrence_prevention_comment" component={RichTextField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('recurrence_prevention_completion_date') ? (
        <FastField name="recurrence_prevention_completion_date" component={DateField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('recurrence_prevention_recorded_by_person_id') ? (
        <FastField
          name="recurrence_prevention_recorded_by_person_id"
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
      ) : (
        <></>
      )}
    </FormSection>
  );
};

export default IncidentPreventativeActions;
