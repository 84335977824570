import React from 'react';
import { FastField } from 'formik';
import _ from 'lodash';
import { FormHeader } from '../../../components/form/FormHeader';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { NumericField } from '../../../components/fields/NumericField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { FieldDefinitions } from '../../../components/other/fieldDefinitions';
import { API } from '../../../services/api';
import { FormDiv } from '../../../components/form/FormDiv';

const t = (key, params) => I18n.t(`event_types.new_edit.${key}`, params);

export const Form = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    values: {
      id,
      maxPositionValue,
      incidentFieldsConfig,
      accidentFieldsConfig,
      incidentFieldsSectionsWithDueDateHint,
      accidentFieldsSectionsWithDueDateHint,
    },
  } = props;

  const fieldCheckbox = (configName, sectionName, fieldName) => (
    <FastField
      name={`${_.camelCase(configName)}.${sectionName}.${fieldName}`}
      label={I18n.t(`activerecord.attributes.event_type.${configName}.${sectionName}.${fieldName}`)}
      component={CheckBoxField}
    />
  );

  const fieldsSection = (configName, config, sectionName, sectionHint) => {
    return (
      <FormSection
        title={t(`sections.${configName}.${sectionName}`)}
        hint={sectionHint.includes(sectionName) ? t('sections.hints.due_date_field') : null}
      >
        {Object.keys(config[sectionName]).map(fieldName =>
          fieldCheckbox(configName, sectionName, fieldName),
        )}
      </FormSection>
    );
  };

  const incidentSections = () => (
    <FormDiv>
      {Object.keys(incidentFieldsConfig).map(sectionName =>
        fieldsSection(
          'incident_fields_config',
          incidentFieldsConfig,
          sectionName,
          incidentFieldsSectionsWithDueDateHint,
        ),
      )}
    </FormDiv>
  );

  const accidentSections = () => (
    <FormDiv>
      {Object.keys(accidentFieldsConfig).map(sectionName =>
        fieldsSection(
          'accident_fields_config',
          accidentFieldsConfig,
          sectionName,
          accidentFieldsSectionsWithDueDateHint,
        ),
      )}
    </FormDiv>
  );

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.event_type',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.event_type'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="name" required component={TextField} />
          <FastField name="is_default" hint={t('hints.is_default')} component={CheckBoxField} />
          <FastField
            name="position"
            required
            roundPrecision={0}
            component={NumericField}
            warning={t('warnings.position', { max_position_value: maxPositionValue })}
          />
          <FastField name="active" component={CheckBoxField} />
        </FormSection>
        {incidentSections()}
        {accidentSections()}
      </DefaultAttributesGroup>
      <Footer {...props} />
      <FieldDefinitions
        title={t('lists.field_definitions.title')}
        empty={!id}
        api={API.administration.eventTypes.fieldDefinitions(id)}
        changesDisabled={isSubmitting}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
      />
    </form>
  );
};
