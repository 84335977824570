import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from '../helpers';
import { RichTextField } from '../../../../../components/fields/RichTextField';
import { FilesField } from '../../../../../components/fields/FilesField';
import { LinksField } from '../../../../../components/fields/LinksField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentInternalInformation = props => {
  const {
    defaultChildrenAttributes,
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('internal_information', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_internal_information')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('internal_notes')}
        name="accident_internal_notes"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('internal_attachments')}
        name="accident_internal_attachments"
        component={FilesField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('internal_links')}
        name="accident_internal_links"
        component={LinksField}
      />
    </FormSection>
  );
};

export default AccidentInternalInformation;
