import React, { useRef } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import { API } from '../../../services/api';
import { Modal } from '../../../components/modals/Modal';
import { AddAccidentModalContent } from './AddAccidentModalContent';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

export const Accidents = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
    accessToAccidents,
  } = props;

  const addAccidentModalRef = useRef(null);
  const listRef = useRef(null);

  const computeReopenable = fetchedData => {
    const accidentsCount = fetchedData.length;
    setFieldValue('reopenable', accidentsCount === 0);
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.accidents.title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addAccidentModalRef.current.open,
              );
            }}
          >
            {t('lists.accidents.add_link')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.requests.incidentRequests.accidents(id).index}
            ref={listRef}
            dataKey="data"
            mapRowHrefs={
              accessToAccidents &&
              (({ attributes: attribute }) =>
                `/occurrences/accidents/${attribute.accident.id}/edit`)
            }
            resourceName={I18n.t('resource_names.incident_requests_accident')}
            mapData={({ attributes }) => [
              [t('lists.accidents.identifier'), attributes.accident.identifier],
              [t('lists.accidents.occurred_at'), attributes.accident.date_of_occurrence],
              [t('lists.accidents.location'), attributes.accident.location],
              <SimpleListDeletionLink
                key="delete"
                modelName={t('lists.accidents.model_name')}
                modalWarning={t('lists.accidents.unlink_accident_warning')}
                modalConfirmationButtonText={t(
                  'lists.accidents.unlink_accident_confirmation_button',
                )}
                onSubmit={async () => {
                  const { ok, data } = await API.requests.incidentRequests
                    .accidents(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.accidents.flash.success.delete'));
                  } else {
                    showBackendErrorMessage(t('lists.accidents.flash.error.delete'), data);
                  }
                }}
              >
                {t('lists.accidents.delete_link')}
              </SimpleListDeletionLink>,
            ]}
            onDataFetched={computeReopenable}
          />
        )}
      </FormSection>
      <Modal title={t('modals.add_incident.title')} ref={addAccidentModalRef}>
        {modalProps => <AddAccidentModalContent {...modalProps} {...props} listRef={listRef} />}
      </Modal>
    </>
  );
};
