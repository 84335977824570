import React, { useMemo } from 'react';
import { shouldAccidentSectionBeVisible } from '../helpers';
import { defaultShouldUpdate } from '../../../../../services/fieldUtils';
import AccidentSummary from './AccidentSummary';
import AccidentDetails from './AccidentDetails';
import AccidentTreatment from './AccidentTreatment';
import AccidentCausalFactors from './AccidentCausalFactors';
import AccidentCorrectiveActions from './AccidentCorrectiveActions';
import AccidentPreventativeActions from './AccidentPreventativeActions';
import AccidentInternalInformation from './AccidentInternalInformation';

const AccidentOptionalSections = props => {
  const {
    values: { status, accidentFieldsConfig },
  } = props;

  const defaultChildrenAttributes = useMemo(
    () => ({
      labelI18nKeyPrefix: 'activerecord.attributes.incident_request',
      shouldUpdate: defaultShouldUpdate,
      disabled: status === 'completed',
      resourceName: I18n.t('resource_names.incident_request'),
    }),
    [status],
  );
  const childrenProps = { defaultChildrenAttributes, ...props };

  return (
    <>
      {shouldAccidentSectionBeVisible('summary', accidentFieldsConfig) ? (
        <AccidentSummary {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldAccidentSectionBeVisible('details', accidentFieldsConfig) ? (
        <AccidentDetails {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldAccidentSectionBeVisible('treatment', accidentFieldsConfig) ? (
        <AccidentTreatment {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldAccidentSectionBeVisible('causal_factors', accidentFieldsConfig) ? (
        <AccidentCausalFactors {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldAccidentSectionBeVisible('corrective_actions', accidentFieldsConfig) ? (
        <AccidentCorrectiveActions {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldAccidentSectionBeVisible('preventative_actions', accidentFieldsConfig) ? (
        <AccidentPreventativeActions {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldAccidentSectionBeVisible('internal_information', accidentFieldsConfig) ? (
        <AccidentInternalInformation {...childrenProps} />
      ) : (
        <></>
      )}
    </>
  );
};

export default AccidentOptionalSections;
