import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
  prepareCustomFieldsAnswers,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = (key, params = {}) => I18n.t(`requests.incident_requests.new_edit.${key}`, params);

const IncidentRequestFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const incidentRequest = IncidentRequestFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(incidentRequest.id)) {
      IncidentRequestFunctions.create(incidentRequest, formikProps, values.afterSave);
    } else {
      IncidentRequestFunctions.update(
        incidentRequest,
        formikProps,
        values.submitType,
        values.afterSave,
      );
    }
  },
  prepareValuesToSubmit: values => {
    values.datetime_reported_zone = values.auxiliaryDatetimeReportedZone;
    values.datetime_of_occurrence_zone = values.auxiliaryDatetimeOfOccurrenceZone;
    values.response_date_zone = values.auxiliaryResponseDateZone;

    return IncidentRequestFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (incidentRequest, formikProps, afterSave) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.requests.incidentRequests.create({
      incident_request: incidentRequest,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/requests/incident_requests/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit', { resource_name }));
      formikProps.resetForm({
        values: IncidentRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  update: async (incidentRequest, formikProps, submitType, afterSave) => {
    const { resource_name } = formikProps.props;
    const response = await API.requests.incidentRequests[
      submitType === 'save' ? 'update' : submitType
    ](incidentRequest.id, { incident_request: incidentRequest });
    const { ok, data } = response;
    if (ok) {
      formikProps.resetForm({
        values: IncidentRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.${snakeCase(submitType)}`, { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${snakeCase(submitType)}`, { resource_name }), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: incidentRequest => ({
    submitType: 'save',
    status: incidentRequest.status,
    subStatus: incidentRequest.sub_status,
    auxiliaryDatetimeReportedZone: incidentRequest.datetime_reported_zone,
    auxiliaryDatetimeOfOccurrenceZone: incidentRequest.datetime_of_occurrence_zone,
    auxiliaryResponseDateZone: incidentRequest.response_date_zone,
    incidentAssignedPersonId: incidentRequest.assigned_person_id,
    accidentFieldsConfig: incidentRequest.accident_fields_config,
    incidentFieldsConfig: incidentRequest.incident_fields_config,
    eventTypeName: incidentRequest.event_type_name,
    incidentRequestFieldDefinitions: incidentRequest.incident_request_field_definitions,
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'datetime_reported',
    'datetime_reported_zone',
    'reporting_person_id',
    'datetime_of_occurrence',
    'datetime_of_occurrence_zone',
    'follow_up_contact',
    'issue_type_id',
    'event_type_id',
    'incident_issue',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_details',
    'assigned_person_id',
    'incident_people_group_id',
    'request_attachments',
    'request_links',
    'reopenable',
    'incident_ids',
    'accident_ids',
    'related_incident_id',
    'related_accident_id',
    'department_id',
    'owner_person_id',
    'short_description',
    'responder_person_id',
    'response_date',
    'response_date_zone',
    'responder_assessment',
    'executive_summary',
    'corrective_actions_required',
    'corrective_actions_due_date',
    'actions_taken',
    'corrective_actions_completion_date',
    'corrective_actions_recorded_by_person_id',
    'recurrence_prevention_required',
    'recurrence_prevention_due_date',
    'recurrence_prevention_comment',
    'recurrence_prevention_completion_date',
    'recurrence_prevention_recorded_by_person_id',
    'other_information',
    'impact_type_id',
    'impact_length',
    'incident_risk_rating_id',
    'cost_implication',
    'report_to_regulatory_agency',
    'label_ids',
    'also_notified_person_ids',
    'incident_request_field_answers_attributes',
    'accident_person_type_id',
    'accident_internal_party_involved_id',
    'accident_pi_director_id',
    'accident_supervisor_id',
    'accident_setting_id',
    'accident_supervisor_text',
    'accident_external_party_involved',
    'accident_injury_type_ids',
    'accident_class_ids',
    'accident_body_part_ids',
    'accident_description',
    'accident_body_side_affected',
    'accident_osha_case_classification',
    'accident_days_away_from_work',
    'accident_days_on_job_transfer_or_restriction',
    'accident_osha_risk_factor_id',
    'accident_details_attachments',
    'accident_details_links',
    'accident_treatment_physician',
    'accident_treatment_outside_worksite',
    'accident_treatment_facility',
    'accident_treatment_street',
    'accident_treatment_city',
    'accident_treatment_state',
    'accident_treatment_zip',
    'accident_employee_treated_in_emergency_room',
    'accident_employee_hospitalized_overnight',
    'accident_unsafe_act_ids',
    'accident_unsafe_personal_factor_ids',
    'accident_unsafe_condition_ids',
    'accident_label_ids',
    'accident_factors_comment',
    'accident_investigation_recommendations',
    'accident_corrective_actions_due_date',
    'accident_investigation_start_date',
    'accident_investigation_close_out_date',
    'accident_actions_taken',
    'accident_corrective_actions_completion_date',
    'accident_corrective_actions_recorded_by_person_id',
    'accident_investigation_text',
    'accident_investigation_attachments',
    'accident_investigation_links',
    'accident_preventive_actions',
    'accident_preventive_actions_due_date',
    'accident_preventive_actions_comments',
    'accident_preventive_actions_completion_date',
    'accident_preventive_actions_recorded_by_id',
    'accident_internal_notes',
    'accident_internal_attachments',
    'accident_internal_links',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    incident_request_field_answers_attributes: prepareCustomFieldsAnswers(
      'incident_request',
      backendValues.incident_request_field_definitions,
      backendValues.incident_request_field_answers_attributes,
    ),
  }),
});

export const IncidentRequestForm = withFormik({
  mapPropsToValues: props => IncidentRequestFunctions.prepareInitialValues(props.incidentRequest),
  handleSubmit: IncidentRequestFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
