import get from 'lodash/get';
import { API } from '../../../services/api';
import { prepareCustomFieldsAnswers, showBackendErrorMessage } from '../../../services/utils';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

export const setEventType = async (newSelection, setFieldValue) => {
  await setFieldValue('eventTypeName', get(newSelection, 'data.text'));
  await setFieldValue('incidentFieldsConfig', get(newSelection, 'data.incident_fields_config'));
  await setFieldValue('accidentFieldsConfig', get(newSelection, 'data.accident_fields_config'));

  const { ok, data } = await API.requests
    .eventTypes(newSelection.value)
    .fieldDefinitions()
    .index();

  if (ok) {
    const definitions = data.data.map(fieldDefinition => fieldDefinition.attributes);
    const answers = prepareCustomFieldsAnswers('incident_request', definitions);
    await setFieldValue('incidentRequestFieldDefinitions', definitions);
    await setFieldValue('incident_request_field_answers_attributes', answers);
  } else {
    showBackendErrorMessage(t('flash.error.unexpected_error'));
  }
};
