import React, { Component } from 'react';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import { ListEmptyPlaceholder } from '../../stateless_components/placeholders/ListEmptyPlaceholder';
import Checklist from '../Checklist/Checklist';
import { SaluteSpinner } from '../../stateless_components/salute_spinner/index';
import { API } from '../../services/api';
import { validateForm } from '../../services/formHelper';
import validationSchema from './ValidationSchema';
import { getHiddenDefinitionsIds } from './helpers';

class InspectionChecklist extends Component {
  state = {
    fetchQuestions: false,
    emptyState: true,
    activeRelatedEntity: '',
    commentFileModalShouldOpenForFindingDefinitionId: null,
    findingsModalShouldOpenForFindingDefinitionId: null,
  };

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();

    const {
      values: {
        id,
        inspection_type_id: inspectionTypeId,
        inspection_checklist_questions_attributes: items,
      },
    } = this.props;

    // The following conditions are met when creating a similar inspection.
    if (!id && inspectionTypeId && isEmpty(items)) this.fetchChecklist();
  }

  componentDidUpdate(prevProps) {
    const {
      setFieldValue,
      values: {
        inspection_type_id: inspectionTypeId,
        checklist_questions_processing_order: processingOrder,
        inspection_checklist_questions_attributes: items,
      },
    } = this.props;
    const {
      values: {
        inspection_type_id: prevInspectionTypeId,
        checklist_questions_processing_order: prevProcessingOrder,
        inspection_checklist_questions_attributes: prevItems,
      },
    } = prevProps;

    $('[data-toggle="tooltip"]').tooltip();

    if (prevInspectionTypeId !== inspectionTypeId) this.fetchChecklist();

    if (prevItems !== items || prevProcessingOrder !== processingOrder) {
      const hiddenDefinitionsIds = getHiddenDefinitionsIds(items, processingOrder);
      setFieldValue('hiddenDefinitionsIds', hiddenDefinitionsIds);
    }
  }

  unsavedInspectionOpenModals = async (optionallyOpenModalForFindingDefinitionId, source) => {
    const { values, setTouched, setErrors, submitForm, setFieldValue } = this.props;
    const errors = validateForm(values, validationSchema);
    if (Object.keys(errors).length === 0) {
      window.flashMessage('Form will be saved, please wait');
      await setFieldValue('send_email_notifications', false);
      await setFieldValue('submit', 'draft');
      if (source === 'CommentFileBtn') {
        this.setState(
          {
            commentFileModalShouldOpenForFindingDefinitionId: optionallyOpenModalForFindingDefinitionId,
          },
          () => submitForm(),
        );
      } else if (source === 'FindingsBtn') {
        this.setState(
          {
            findingsModalShouldOpenForFindingDefinitionId: optionallyOpenModalForFindingDefinitionId,
          },
          () => submitForm(),
        );
      }
    } else {
      const touched = {};
      Object.keys(values).forEach(key => (touched[key] = true));
      setTouched(touched);
      setErrors(errors);
      window.flashMessage('Form must be correctly filled', true, 'error');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return false;
  };

  savedInspectionOpenModals = async () => {
    const { values, setTouched, setErrors, submitForm, setFieldValue } = this.props;
    const errors = validateForm(values, validationSchema);
    if (Object.keys(errors).length === 0) {
      window.flashMessage('Form will be saved, please wait');
      await setFieldValue('send_email_notifications', false);
      await setFieldValue('submit', 'draft');
      await setFieldValue('afterSave', () => {
        this.setState({
          commentFileModalShouldOpenForFindingDefinitionId: null,
          findingsModalShouldOpenForFindingDefinitionId: null,
        });
      });
      await submitForm();
      return true;
    }
    const touched = {};
    Object.keys(values).forEach(key => (touched[key] = true));
    setTouched(touched);
    setErrors(errors);
    window.flashMessage('Form must be correctly filled', true, 'error');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return false;
  };

  beforeOpeningChecklistModal = async (optionallyOpenModalForFindingDefinitionId, source) => {
    const {
      values: { id, status },
    } = this.props;
    if (!id) {
      return await this.unsavedInspectionOpenModals(
        optionallyOpenModalForFindingDefinitionId,
        source,
      );
    }
    if (status === 'draft') {
      return await this.savedInspectionOpenModals();
    }
    return true;
  };

  setAnswer = (id, answer) => {
    if (this.props.values.id) {
      this.setAnswerToState(id, answer);
    } else {
      this.setAnswerToState(id, answer, false);
    }
  };

  setAnswerToState = (id, answer, persisted = true, cb = () => {}) => {
    const { inspection_checklist_questions_attributes: items } = this.props.values;
    const newItems = produce(items, draftState => {
      const question = draftState.find(item => {
        return persisted ? item.id === id : item.inspection_finding_definition_id === id;
      });
      question.answer = answer;
    });

    this.props.updateQuestions(newItems, cb);
  };

  fetchChecklist = () => {
    this.setState({ fetchQuestions: true }, () => {
      this.props.setIsLoadingQuestions(true);
      this.updateChecklist();
    });
  };

  updateChecklist = () => {
    const {
      values: { id, inspection_type_id },
    } = this.props;
    const params = id ? { inspection_id: id } : { inspection_type_id };
    if (inspection_type_id) {
      API.inspection.inspectionChecklistQuestion
        .index({ ...params, ...this.props.params })
        .then(response => {
          const { checklist_questions, checklist_questions_processing_order } = response.data;
          this.setState({ fetchQuestions: false }, () =>
            this.props.updateQuestions(checklist_questions.map(item => item.attributes)),
          );
          this.props.setFieldValue(
            'checklist_questions_processing_order',
            checklist_questions_processing_order,
          );
          this.props.setIsLoadingQuestions(false);
        });
    } else {
      this.setState({ fetchQuestions: false, emptyState: true });
      this.props.setIsLoadingQuestions(false);
    }
  };

  saveCommentAndFile = (questionID, comment, attachments, links, afterSave) => {
    const {
      setFieldValue,
      submitForm,
      values: { inspection_checklist_questions_attributes: items },
    } = this.props;
    const newItems = produce(items, draftState => {
      const question = draftState.find(item => item.id === questionID);
      question.comment = comment;
      question.attachments = attachments;
      question.links = links;
      return draftState;
    });
    this.props.updateQuestions(newItems, async () => {
      await setFieldValue(
        'activeQuestion',
        newItems.find(item => item.id === questionID),
      );
      await setFieldValue('submit', 'update_checklist_comment');
      await setFieldValue('afterSave', afterSave);
      await submitForm();
    });
    return undefined;
  };

  renderChecklist = () => {
    const { fetchQuestions } = this.state;
    const {
      errors,
      current_person,
      admin_access,
      people_groups_count,
      internal,
      enums,
      setFieldValue,
      customInvestigableTypesNames,
      values: {
        people_group_id,
        hiddenDefinitionsIds,
        responsible_person_id,
        investigable_type,
        investigable,
        investigable_id,
        status,
        inspection_checklist_questions_attributes: items,
        activeQuestion,
        inspections_also_notified_people_attributes: alsoNotifiedPeopleAttrs,
      },
    } = this.props;

    const FindingsModuleProps = {
      setActiveRelatedEntity: activeRelatedEntity =>
        setFieldValue('activeQuestion', activeRelatedEntity),
      activeRelatedEntity: activeQuestion,
      refreshList: this.updateChecklist,
      findingStatuses: enums.inspection_finding_status,
      adminAccess: admin_access,
      relatedEntityAttribute: 'inspection_checklist_question_id',
      peopleGroupsCount: people_groups_count,
      customInvestigableTypesNames,
      defaults: {
        creator_id: current_person.id,
        inspection_checklist_question_id: activeQuestion.id,
        inspection_finding_definition_id: activeQuestion.inspection_finding_definition_id,
        label_ids: activeQuestion.label_ids,
        investigable_type,
        investigable,
        investigable_id,
        responsible_person_id,
        people_group_id,
        internal,
        alsoNotifiedPeopleIds: alsoNotifiedPeopleAttrs.map(person => person.person_id),
      },
    };

    if (!fetchQuestions) {
      if (items) {
        return (
          <Checklist
            items={items}
            errors={errors}
            checklistItemRowErrorKey="inspection_checklist_questions_attributes"
            setAnswer={this.setAnswer}
            findingProps={FindingsModuleProps}
            saveCommentAndFile={this.saveCommentAndFile}
            disabled={status === 'finalized'}
            resolveInputName={(i, attributeName) =>
              `inspection[inspection_checklist_question_attributes][${i}][${attributeName}]`
            }
            canOpenCommentFileModal={this.beforeOpeningChecklistModal}
            commentFileModalShouldOpenForFindingDefinitionId={
              this.state.commentFileModalShouldOpenForFindingDefinitionId
            }
            canOpenFindingsModal={this.beforeOpeningChecklistModal}
            findingsModalShouldOpenForFindingDefinitionId={
              this.state.findingsModalShouldOpenForFindingDefinitionId
            }
            hideItem={item => hiddenDefinitionsIds.includes(item.inspection_finding_definition_id)}
            domainName={this.props.domain_name}
            values={this.props.values}
          />
        );
      }
      return <ListEmptyPlaceholder message="No Checklist available for selected inspection type" />;
    }
    return false;
  };

  render() {
    const { fetchQuestions } = this.state;
    const { inspection_type_id } = this.props.values;

    return (
      <div className="pt-5 pb-2">
        {this.renderChecklist()}

        {fetchQuestions && <SaluteSpinner style={{ margin: 'auto' }} name="circle" />}

        {!fetchQuestions && !inspection_type_id && (
          <ListEmptyPlaceholder message="Please select Inspection Type" />
        )}
      </div>
    );
  }
}

export default InspectionChecklist;
