import React, { useMemo } from 'react';
import { shouldIncidentSectionBeVisible } from '../helpers';
import IncidentDetails from './IncidentDetails';
import IncidentResponse from './IncidentResponse';
import IncidentSummary from './IncidentSummary';
import IncidentCorrectiveActions from './IncidentCorrectiveActions';
import IncidentPreventativeActions from './IncidentPreventativeActions';
import IncidentInternalInformation from './IncidentInternalInformation';
import { defaultShouldUpdate } from '../../../../../services/fieldUtils';

const IncidentOptionalSections = props => {
  const {
    values: { status, incidentFieldsConfig },
  } = props;

  const defaultChildrenAttributes = useMemo(
    () => ({
      labelI18nKeyPrefix: 'activerecord.attributes.incident_request',
      shouldUpdate: defaultShouldUpdate,
      disabled: status === 'completed',
      resourceName: I18n.t('resource_names.incident_request'),
    }),
    [status],
  );
  const childrenProps = { defaultChildrenAttributes, ...props };

  return (
    <>
      {shouldIncidentSectionBeVisible('details', incidentFieldsConfig) ? (
        <IncidentDetails {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldIncidentSectionBeVisible('response', incidentFieldsConfig) ? (
        <IncidentResponse {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldIncidentSectionBeVisible('summary', incidentFieldsConfig) ? (
        <IncidentSummary {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldIncidentSectionBeVisible('corrective_actions', incidentFieldsConfig) ? (
        <IncidentCorrectiveActions {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldIncidentSectionBeVisible('preventative_actions', incidentFieldsConfig) ? (
        <IncidentPreventativeActions {...childrenProps} />
      ) : (
        <></>
      )}
      {shouldIncidentSectionBeVisible('internal_information', incidentFieldsConfig) ? (
        <IncidentInternalInformation {...childrenProps} />
      ) : (
        <></>
      )}
    </>
  );
};

export default IncidentOptionalSections;
