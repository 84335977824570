import React from 'react';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink';

const t = key => I18n.t(`generic_submission_panel.actions.${key}`);

const NotFinalizedFooter = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values,
  closeModal,
  admin_access,
  initialInspection,
}) => {
  return (
    <div className="mt-3 d-flex flex-wrap justify-content-end">
      <div className="mr-auto d-flex justify-content-start">
        {admin_access && values.id && initialInspection.status === 'draft' && (
          <ButtonLink
            secondary
            fontSizeVariant="textMd"
            className="mt-3 text-danger"
            onClick={async (...args) => {
              if (
                window.confirm(
                  I18n.t('inspections.inspections.form.hints.sure_to_delete_violation'),
                )
              ) {
                await setFieldValue('submit', 'delete');
                await setFieldValue('create_similar', false);
                handleSubmit(...args);
              }
            }}
            disabled={isSubmitting}
          >
            <i className="fas fa-trash mr-2" />
            {I18n.t('inspections.inspections.form.modals.violation.delete')}
          </ButtonLink>
        )}
      </div>

      <ButtonLink secondary className="mt-3 ml-sm-3" onClick={closeModal} fontSizeVariant="textMd">
        {t('cancel')}
      </ButtonLink>
      <PrimaryButton
        secondary
        className="mt-3 ml-sm-3"
        onPress={async (...args) => {
          await setFieldValue('submit', 'draft');
          await setFieldValue('create_similar', true);
          handleSubmit(...args);
        }}
        disabled={isSubmitting}
      >
        {t('save_and_create_similar')}
      </PrimaryButton>
      <PrimaryButton
        secondary
        className="mt-3 ml-sm-3"
        onPress={async (...args) => {
          await setFieldValue('submit', 'draft');
          await setFieldValue('create_similar', false);
          handleSubmit(...args);
        }}
        disabled={isSubmitting}
      >
        {t('plain_save')}
      </PrimaryButton>
      <PrimaryButton
        className="mt-3 ml-sm-3"
        onPress={async (...args) => {
          await setFieldValue('submit', 'finalize');
          await setFieldValue('create_similar', false);
          handleSubmit(...args);
        }}
        disabled={isSubmitting}
      >
        {t('finalize')}
      </PrimaryButton>
    </div>
  );
};

const FinalizedFooter = ({ handleSubmit, setFieldValue, isSubmitting, closeModal }) => {
  return (
    <div className="mt-3 d-flex flex-wrap justify-content-end">
      <ButtonLink secondary className="mt-3 ml-sm-3" onClick={closeModal} fontSizeVariant="textMd">
        {t('cancel')}
      </ButtonLink>
      <PrimaryButton
        onPress={async (...args) => {
          await setFieldValue('submit', 'reopen');
          await setFieldValue('create_similar', false);
          handleSubmit(...args);
        }}
        disabled={isSubmitting}
        className="mt-3 ml-sm-3"
      >
        {t('reopen')}
      </PrimaryButton>
    </div>
  );
};

export const ViolationFooter = props => {
  if (props.values.status === 'finalized') {
    return <FinalizedFooter {...props} />;
  }
  return <NotFinalizedFooter {...props} />;
};
