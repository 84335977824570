import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const IndexTableBottomHeader = ({ children }) => {
  return <Container>{children}</Container>;
};
