import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { produceShouldFieldBeVisible } from '../helpers';
import { FormSection } from '../../../../../components/form/FormSection';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncMultiSelectorField } from '../../../../../components/fields/AsyncMultiSelectorField';
import { RichTextField } from '../../../../../components/fields/RichTextField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentCausalFactors = props => {
  const {
    defaultChildrenAttributes,
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('causal_factors', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_causal_factors')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('accident_unsafe_act_ids')}
        name="accident_unsafe_act_ids"
        api={API.selectors.accidentUnsafeActs.index}
        selectionApi={API.selectors.accidentUnsafeActs.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('accident_unsafe_personal_factor_ids')}
        name="accident_unsafe_personal_factor_ids"
        api={API.selectors.accidentUnsafePersonalFactors.index}
        selectionApi={API.selectors.accidentUnsafePersonalFactors.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('accident_unsafe_condition_ids')}
        name="accident_unsafe_condition_ids"
        api={API.selectors.accidentUnsafeConditions.index}
        selectionApi={API.selectors.accidentUnsafeConditions.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('factors_comment')}
        name="accident_factors_comment"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('label_ids')}
        name="accident_label_ids"
        api={API.selectors.labels.index}
        selectionApi={API.selectors.labels.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
    </FormSection>
  );
};

export default AccidentCausalFactors;
