import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from '../helpers';
import { mapToSelectorOptions, produceShouldUpdate } from '../../../../../services/fieldUtils';
import { SelectorField } from '../../../../../components/fields/SelectorField';
import { DateTimeField } from '../../../../../components/fields/DateTimeField';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { defaultOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { API } from '../../../../../services/api';
import { RichTextField } from '../../../../../components/fields/RichTextField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const IncidentResponse = props => {
  const {
    defaultChildrenAttributes,
    enums: { timeZones },
    values: { incidentFieldsConfig, response_date_zone, auxiliaryResponseDateZone },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('response', incidentFieldsConfig)(field),
    [incidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.incident_response')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      {shouldFieldBeVisible('responder_person_id') ? (
        <FastField
          name="responder_person_id"
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          hint={t('hints.responder_person')}
          component={AsyncSelectorField}
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('response_date') ? (
        <FastField
          name="response_date"
          component={DateTimeField}
          timeZones={{
            fixedTimeZone: response_date_zone,
            inputTimeZone: auxiliaryResponseDateZone,
          }}
          shouldUpdate={produceShouldUpdate(
            [],
            [],
            ['response_date_zone', 'auxiliaryResponseDateZone'],
          )}
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('response_date') ? (
        <FastField
          name="auxiliaryResponseDateZone"
          options={mapToSelectorOptions(timeZones, 'header', 'value')}
          component={SelectorField}
          label=""
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('responder_assessment') ? (
        <FastField
          name="responder_assessment"
          hint={t('hints.responder_assessment')}
          component={RichTextField}
        />
      ) : (
        <></>
      )}
    </FormSection>
  );
};

export default IncidentResponse;
