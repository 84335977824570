import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { produceShouldFieldBeVisible } from '../helpers';
import { FormSection } from '../../../../../components/form/FormSection';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { RichTextField } from '../../../../../components/fields/RichTextField';
import { DateField } from '../../../../../components/fields/DateField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentPreventativeActions = props => {
  const {
    defaultChildrenAttributes,
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('preventative_actions', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_preventative_actions')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('preventive_actions')}
        name="accident_preventive_actions"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('preventive_actions_due_date')}
        name="accident_preventive_actions_due_date"
        component={DateField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('preventive_actions_comments')}
        name="accident_preventive_actions_comments"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('preventive_actions_completion_date')}
        name="accident_preventive_actions_completion_date"
        component={DateField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('preventive_actions_recorded_by_id')}
        name="accident_preventive_actions_recorded_by_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
      />
    </FormSection>
  );
};

export default AccidentPreventativeActions;
