import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { produceShouldFieldBeVisible } from '../helpers';
import { FormSection } from '../../../../../components/form/FormSection';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { AsyncMultiSelectorField } from '../../../../../components/fields/AsyncMultiSelectorField';
import { TextField } from '../../../../../components/fields/TextField';
import { RichTextField } from '../../../../../components/fields/RichTextField';
import { FilesField } from '../../../../../components/fields/FilesField';
import { LinksField } from '../../../../../components/fields/LinksField';
import { SelectorField } from '../../../../../components/fields/SelectorField';
import { mapToSelectorOptions } from '../../../../../services/fieldUtils';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const AccidentDetails = props => {
  const {
    defaultChildrenAttributes,
    enums: { accidentBodySideAffected, accidentOshaCaseClassification },
    values: { accidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('details', accidentFieldsConfig)(field),
    [accidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.accident_details')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        hidden={!shouldFieldBeVisible('injury_type_ids')}
        name="accident_injury_type_ids"
        api={API.selectors.accidentInjuryTypes.index}
        selectionApi={API.selectors.accidentInjuryTypes.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('description')}
        name="accident_description"
        component={RichTextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('accident_class_ids')}
        name="accident_class_ids"
        api={API.selectors.accidentClasses.index}
        selectionApi={API.selectors.accidentClasses.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('affected_body_part_ids')}
        name="accident_body_part_ids"
        api={API.selectors.accidentBodyParts.index}
        selectionApi={API.selectors.accidentBodyParts.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('body_side_affected')}
        name="accident_body_side_affected"
        options={mapToSelectorOptions(accidentBodySideAffected, 'header', 'value')}
        component={SelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('osha_risk_factor_id')}
        name="accident_osha_risk_factor_id"
        api={API.selectors.accidentOshaRiskFactors.index}
        selectionApi={API.selectors.accidentOshaRiskFactors.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('osha_case_classification')}
        name="accident_osha_case_classification"
        options={mapToSelectorOptions(accidentOshaCaseClassification, 'header', 'value')}
        component={SelectorField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('days_away_from_work')}
        name="accident_days_away_from_work"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('days_on_job_transfer_or_restriction')}
        name="accident_days_on_job_transfer_or_restriction"
        component={TextField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('details_attachments')}
        name="accident_details_attachments"
        component={FilesField}
      />
      <FastField
        hidden={!shouldFieldBeVisible('details_links')}
        name="accident_details_links"
        component={LinksField}
      />
    </FormSection>
  );
};

export default AccidentDetails;
