import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';

export const ModalPaper = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid ${colors.sectionSeparatorColor};
  padding: 15px;
  &:last-child {
    margin-bottom: 20px;
  }
`;
